// subheader for e.G. "Fachverbände" and "Junge DPG"
// flexbox w/ fallback-code

.subheader {
  padding: 0.6em 0;
  margin-top: 1px;
  background: #42a7f5;
  color: @white;

  @media (max-width: @screen-xs-max) {
    padding: 0;
  }

  a.back-to-overview {
    display: block;
    padding: 0.7em 0;
    border-right: 1px solid fade(@white, 50%);
    margin-top: 0.5em;
    color: fade(@white, 50%);
    font: 600 1em/1.2 @copy-font;
    text-decoration: none;

    &:hover {
      color: @white;
    }

    span {
      display: inline-block;
      width: 93%;
      padding-left: 1.1em;

      @media (max-width: @screen-xs-max) {
        display: none;
      }
    }

    svg {
      display: inline-block;
      width: 13px;
      height: 22px;
    }
  }

  h1 {
    margin: 0;
    color: @blue;
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

  h3 {
    margin: 0;
  }

  // Liegenschaften (yellow)

  &.yellow {
    background: @green;

    .container {
      background: url(../img/Logo-PBH.png) no-repeat right center;
      background-size: contain;

      @media (max-width: @screen-sm-max) {
        background: transparent;
      }
    }

    a.back-to-overview {
      border-color: darken(@green, 20%);
      color: darken(@green, 20%);

      &:hover {
        color: darken(@green, 30%);
      }
    }
  }

  &.grey {
    background: @gray-light;

    .container {
      // background: url(../img/Logo-PBH.png) no-repeat right center;
      background-size: contain;

      @media (max-width: @screen-sm-max) {
        background: transparent;
      }
    }
  }
}

.subsection-magnus-haus-berlin .subheader.yellow .container {
  background: url(../img/Logo_Magnus-Haus_blau_mittel.png) no-repeat right
    center;
}

// all shiny and chrome: flexbox

.flexbox {
  .subheader {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    a.back-to-overview {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
    }
  }
}

// very special for jDPG:

.super-subheader {
  margin-bottom: -1px;
  background: @blue;

  h1,
  h3 {
    color: @white;
  }
}
