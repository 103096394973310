/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
  font-family: @copyFont;
  font-size: 16px;
}

// the header
// ===================================

header {
  position: relative;

  .burger {
    display: none;
  }
  // the new burger

  @media (max-width: @screen-md-min) {
    .burger {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      border-right: 1px solid @line-color-gray;
      color: @blue;
    }

    button.burger-icon {
      display: block;
      width: 56px;
      height: 54px;
      padding: 0.83em 1.03em;
      border: none;
      margin: 0;
      background: transparent;
      outline: none;
      transition: background 0.4s ease-in-out;

      path {
        transition: all 0.4s ease-in-out;
      }

      path#burger-upper {
        transform: rotate(0deg);
        transform-origin: 22px 1px;
      }

      path#burger-lower {
        transform: rotate(0deg);
        transform-origin: 23px 16px;
      }

      path#burger-middle {
        opacity: 1;
      }

      &.close-button {
        //background:@blue-light;
        path#burger-upper {
          transform: translateX(-2px) rotate(-45deg);
          //transform-origin: right top;
        }

        path#burger-lower {
          transform: translateX(-2px) rotate(45deg);
          //transform-origin: right bottom;
        }

        path#burger-middle {
          opacity: 0;
        }
      }
    }
  }

  // EOF new burger

  .logo-support {
    .clearfix;

    border-bottom: 1px solid @line-color-gray;

    .logo {
      width: 100%;
      padding: 0.6em;
      text-align: center;

      svg {
        width: 74px;
        height: 36px;
        color: @blue;
      }
    }

    .support-navi,
    .login,
    .language,
    .search {
      display: none;
    }
  }

  @media (min-width: @screen-md-min) {
    .burger {
      display: none;
    }

    .logo-support {
      .clearfix;

      border-bottom: 1px solid @line-color-gray;

      .logo {
        width: auto;
        float: left;
        text-align: left;

        svg {
          width: 101px;
          height: 50px;
          color: @blue;

          @media (min-width: @screen-lg-min) {
            width: 113px;
            height: 56px;
          }
        }
      }

      .support-navi,
      .login,
      .language,
      .search {
        display: block;
        padding: 3.33em 1.4em;
        border-left: 1px solid @line-color-gray;
        color: @blue;
        float: right;
        font: 400 0.9em/1.6 @copy-font;

        a {
          position: relative;
          color: @blue;
          text-decoration: none;
        }

        .icon {
          position: relative;
          display: inline-block;
        }

        .icon-lock {
          top: -2px;
          width: 16px;
          height: 19px;
          margin-right: 5px;
        }

        .icon-globe {
          top: -2px;
          width: 21px;
          height: 21px;
          margin-right: 5px;
        }

        .icon-search {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }

        .icon-kontakt {
          width: 26px;
          height: 22px;
        }
      }

      .support-navi {
        border: none;

        ul,
        li {
          display: inline-block;

          a {
            margin-left: 1em;
          }
        }
      }

      // nav has own .less file

      .login {
      }

      .login.logged-in {
        a.btn-logout {
          padding: 0.7em;
          border: 1px solid @blue;
          margin-left: 0.8em;
          font-weight: 600;
        }
      }

      .language,
      .login {
        position: relative;
        cursor: pointer;

        .icon-chevron-down {
          width: 10px;
          height: 6px;
        }

        ul {
          position: absolute;
          z-index: 9999;
          top: 5.2em;
          left: 0;
          display: block;
          width: 11em;
          padding: 0;
          margin: 0;
          background: #fff;
          background: fade(@white, 95%);
          box-shadow: 0 2px 2px fade(@black, 20%);
          list-style: none;
          opacity: 0;
          transition: opacity 0.2s linear, visibility 0.2s linear;
          transition-delay: 0.2s;
          visibility: hidden;

          li {
            a {
              display: block;
              padding: 7px 17px;
              border-top: 1px solid #dfdfdf;
              margin: 0;
              background: none;
              clear: both;
              font-size: 1em;
              font-weight: 600;
              text-align: left;
              text-decoration: none;
              text-transform: none;

              &:hover {
                background: @header-blue;
              }
            }
          }
        }

        &:hover {
          ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .search {
        display: flex;
        padding: 3.15em 1.4em;

        form.searchform {
          display: inline-block;
          overflow: hidden;
          width: 0;
          transition: width 0.2s ease-in-out;

          input {
            width: 0;
          }

          &.open {
            width: 15em;

            input {
              width: auto;
              margin-left: 1em;
            }
          }
        }
      }
    }
  }
}

@media (min-width: @screen-xs-max) and (max-width: @screen-md-max) {
  .language-text,
  .login-text {
    display: none;
  }
}

@media (max-width: @screen-sm-max) {
  .login-text {
    display: inline;
  }
}
