// box for authors

.author {
  font-size: 85%;

  .author-inner {
    padding: 1em;
    background: fade(@header-blue, 50%);
    text-align: center;
  }
}

aside.author {
  width: 33.333333%;
  margin: 1em 0 1.4em 2.4em;
  float: right;
}

// Kontakte (OÜ-Daten)

.author-listing {
  .author {
    margin-bottom: 2em;
    font-size: 80%;

    .author-inner {
      display: flex;
      width: 100%;
      text-align: left;

      .clearfix;

      .author-image {
        // width:8em;
        width: 33%;
        flex-grow: 0;
        flex-shrink: 0;

        img {
          width: 100%;
        }

        @media (min-width: @screen-lg-min) {
          // width: 13em;
          width: 43%;
        }
      }

      .author-text {
        flex: 1;
        padding-left: 1em;

        @media (min-width: @screen-sm-min) {
          min-height: 19em;
        }

        h3 {
          margin: 0;
        }

        p {
          margin-bottom: 0.5em;
        }
      }
    }
  }
}

.author-listing .bad-honnef {
  display: flex;
  flex-wrap: wrap;

  .author {
    display: flex;
    flex: 0 0 30%;
    align-items: stretch;
    margin-right: 1em;

    @media (max-width: @screen-sm-max) {
      flex: 0 0 100%;
    }
  }

  .author-inner {
    flex-direction: column;

    .author-image {
      width: 100%;
    }

    .author-text {
      padding: 1em 0 0;
    }
  }
}

.author-listing .berlin {
  display: flex;
  flex-wrap: wrap;

  .author {
    display: flex;
    flex: 0 0 30%;
    margin-right: 1em;

    @media (max-width: @screen-sm-max) {
      flex: 0 0 100%;
    }
  }
}

.portlet.author h2 {
  padding: 0;
  margin: 0 0 1em;
}
