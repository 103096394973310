
.filter-form-container {
  padding-bottom: 1em;
  border-bottom: 1px solid @line-color-gray;
}

.form.filter-form {
  label {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 0.5em;
  }

  .element {
    margin-top: 1em;
  }

  .element.separator {
    padding-right: 1.5em;
    border-right: 1px solid @line-color-gray;

    @media (max-width: @screen-sm-max) {
      padding-right: 0;
      border: none;
    }
  }

  // Fake pull down for the filter

  .filter-box {
    position: relative;
    width: 100%;
    padding: 0.4em 1em;
    border: 1px solid @line-color-gray;
    background: transparent;
    border-radius: 0;
    color: @font-color;
    font: 1em/2 @copy-font;

    &::after {
      position: absolute;
      top: 0.5em;
      right: 0.8em;
      width: 1.5em;
      height: 1.5em;
      border-left: 1px solid @line-color-gray;
      background: url(../img/icon-chevron-down.svg) no-repeat right 0.6em;
      content: "";
    }

    .icon {
      position: relative;
      display: inline-block;
    }

    .icon-chevron-down {
      width: 10px;
      height: 6px;
    }

    ul {
      position: absolute;
      z-index: 9999;
      top: 2.9em;
      left: 0;
      display: block;
      width: 32em;
      padding: 0;
      padding: 1em;
      border-top: 1px solid fade(@black, 10%);
      margin: 0;
      background: #fff;
      background: fade(@white, 99%);
      box-shadow: 0 2px 2px fade(@black, 20%);
      list-style: none;
      opacity: 0;
      transition: opacity 0.2s linear, visibility 0.2s linear;
      transition-delay: 0.2s;
      visibility: hidden;

      @media (max-width: @screen-sm-max) {
        width: 20em;
      }

      @media (min-width: @screen-sm-min) {
        column-count: 2;
        column-gap: 10px;
      }

      li {
        .clearfix;

        padding: 0.1em 0 0 0.8em;

        &::after {
          display: none;
        }

        input[type="check"] {
        }

        label {
          color: darken(@text-color, 10%);
          font: 400 0.95em/1 @copy-font;
        }
      }
    }

    &:hover {
      ul {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

